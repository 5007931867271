import { BOTONIC_STORAGE_KEY } from '../../constants'

export interface BotonicState {
  lastMessageUpdate: string
  lastRoutePath: string
  messages: Record<string, any>[]
  session: Record<string, any>
  themeUpdates: Record<string, any>
}

export class BotonicState {
  protected static storageKey = BOTONIC_STORAGE_KEY

  static get(): BotonicState {
    const storage = window.inMemoryStorage.getItem(this.storageKey) as string
    return JSON.parse(storage) || {}
  }
}

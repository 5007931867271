import { BOT_STORAGE_KEY } from '../../constants'

export interface WebchatState {
  isDoingHandoff: boolean
}

export class WebchatState {
  protected static storageKey = BOT_STORAGE_KEY

  static get(): WebchatState {
    const storage = window.inMemoryStorage.getItem(this.storageKey) as string
    return JSON.parse(storage) || {}
  }
}

import { BotonicState } from "../domain/storage/botonic-state"


const EASYJET_COOKIE_NAME =
  'EASYJET_ENSIGHTEN_PRIVACY_Performance_and_Personalisation'
const HUBTYPE_COOKIE_NAME = 'botonic_user_id'
const EXPIRATION_DAYS_MS = 30 * 24 * 60 * 60 * 1000

export function getUserIdFromCookie(): string {
  const cookies = document.cookie.split(';')

  let userId = cookies
    .find(cookie => new RegExp(`${HUBTYPE_COOKIE_NAME}=`).test(cookie))
    ?.split(`${HUBTYPE_COOKIE_NAME}=`)[1]

  if (!userId) {
    userId = setUserIdInCookie()
  }

  return userId
}

function setUserIdInCookie(): string {
  const path = window.location.pathname
  const date = new Date()
  date.setTime(date.getTime() + EXPIRATION_DAYS_MS)
  const expires = 'expires=' + date.toUTCString()
  const userId = BotonicState.get().session.user.id
  document.cookie = `${HUBTYPE_COOKIE_NAME}=${userId}; ${expires}; path=${path}`

  return userId
}

export function updateUserIdCookieIfExists(newUserId: string): void {
  const cookies = document.cookie.split(';')
  const hubtypeCookie = cookies.find(cookie =>
    new RegExp(`${HUBTYPE_COOKIE_NAME}=`).test(cookie.trim())
  )

  if (hubtypeCookie) {
    const path = window.location.pathname
    const date = new Date()
    date.setTime(date.getTime() + EXPIRATION_DAYS_MS)
    const expires = 'expires=' + date.toUTCString()
    document.cookie = `${HUBTYPE_COOKIE_NAME}=${newUserId}; ${expires}; path=${path}`
  }
}

export function areCookiesAlowed(): boolean {
  const ensightenCookie = getEnsightenCookie()
  return ensightenCookie === '1'
}

export function getEnsightenCookie(): string | undefined {
  const cookies = document.cookie.split(';')

  return cookies
    .find(cookie => new RegExp(`${EASYJET_COOKIE_NAME}=`).test(cookie))
    ?.split(`${EASYJET_COOKIE_NAME}=`)?.[1]
}
